/** @type {React.Context<MenuStructureContext>} */
const MenuStructureContext = React.createContext(null)

export function MenuStructureContextProvider({ children }) {
  const menuStructureRef = React.useRef({})
  const submenuStructureRef = React.useRef({})

  const menu = menuStructureRef.current
  const submenu = submenuStructureRef.current

  return (
    <MenuStructureContext.Provider
      value={{ structure: { menu, submenu }, setMenuItemRef, setSubmenuItemRef }}
      {...{ children }}
    />
  )

  function setMenuItemRef(item, x) {
    if (item && x) menuStructureRef.current[item._key] = x
  }

  function setSubmenuItemRef(parentId, item, x) {
    if (parentId && item && x) submenuStructureRef.current[parentId]
      ? submenuStructureRef.current[parentId][item._key] = x
      : submenuStructureRef.current[parentId] = { [item._key]: x }
  }
}

export function useMenuStructureContext() {
  return React.useContext(MenuStructureContext)
}

/**
 * @typedef MenuStructureContext
 * @property {Structure} structure
 * @property {(item: object, x: HTMLElement | Element) => void} setMenuItemRef
 * @property {(parentId: string, item: object, x: HTMLElement | Element) => void} setSubmenuItemRef
 */

/**
 * @typedef Structure
 * @property {Record<string, object>} menu
 * @property {Record<string, object>} submenu
 */
