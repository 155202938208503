import { FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { MenuStructureContextProvider } from './MenuStructureContext'

import { routeMap } from '/routeMap'

import { NavigationContextProvider, useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { ContainerFlexibleLg } from '/features/buildingBlocks/Container'
import { MenuDesktop } from '/features/pageOnly/menu/MenuDesktop'
import { MenuMobile } from '/features/pageOnly/menu/MenuMobile'

import styles from './Menu.css'

export default function Menu({
  menuItems,
  translations,
  currentLocation,
  logoHref,
  fixedButtons,
  skillsMatchUserSelectionCount,
  showSkillsMatchButton = false
}) {
  const items = React.useMemo(
    () => menuItems.map(item => mapMenuItem({ item, currentLocation })),
    [menuItems, currentLocation]
  )

  return (
    <MenuStructureContextProvider>
      <NavigationContextProvider {...{ translations }}>
        <Navigation {...{ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
      </NavigationContextProvider>
    </MenuStructureContextProvider>
  )
}

function Navigation({ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }) {
  const isMounted = useRenderOnMount()
  const { submenuIsOpen, mobileMenuIsExpanded } = useNavigationContext()

  /**
   * Note: This custom size is delibrate, to tailor the menu to fit.
   *       Don't change this value unless you have actively tested
   *       it's implications, and checked it visually in the browser.
   */
  const isCustomViewportSize = useMediaQuery('screen and (min-width: 1205px)') ?? true

  const isOpen = submenuIsOpen || mobileMenuIsExpanded

  return (
    <FloatingOverlayBase lockScroll={mobileMenuIsExpanded} className={cx(styles.componentNavigation, isOpen && styles.isOpen)}>
      {isMounted && (
        <ContainerFlexibleLg>
          {isCustomViewportSize
            ? <MenuDesktop {...{ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
            : <MenuMobile {...{ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
          }
        </ContainerFlexibleLg>
      )}
    </FloatingOverlayBase>
  )
}

function mapMenuItem({ item, currentLocation }) {
  if (['referenceWithLabel', 'submenuItem'].includes(item._type)) {
    const href = item?.ref && determineDocumentPathSync({ document: item.ref, routeMap })

    return { ...item, href, isActive: currentLocation === href }
  }

  const submenuItems = item.submenuItems?.map(x => mapMenuItem({ item: x, currentLocation })) || []

  return { ...item, submenuItems }
}
