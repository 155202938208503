// /** @see https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/hooks/useRole.ts */

/**
 * @param {import("@floating-ui/react").FloatingRootContext} context
 * @param {Props} props
 * @returns {import("@floating-ui/react").ElementProps}
 */
export function useAriaSpecMenu(context, { id: _id, structure, onSubmenuChange }) {
  const { floatingId  } = context
  const id = _id || floatingId

  /** @type {import("@floating-ui/react").ElementProps['reference']} */
  const reference = React.useMemo(() => ({
    role: 'menuitem',
    onKeyDown: e => internalMenuitemKeydown(e, { id, context, structure, onSubmenuChange })
  }), [id, context, structure, onSubmenuChange])

  /** @type {import("@floating-ui/react").ElementProps['item']} */
  const item = React.useMemo(() => ({
    onKeyDown: e => handleItemKeydown(e, { id, structure, onSubmenuChange })
  }), [id, structure, onSubmenuChange])

  return React.useMemo(
    () => ({ reference, item }),
    [reference, item],
  )
}

/**
 * @param {React.KeyboardEvent<Element>} e
 * @param {Props & { reference?: HTMLElement }} _
 */
export function externalMenuitemKeydown(e, { id, structure, onSubmenuChange }) {
  return handleMenuitemKeydown(e, { id, structure, onSubmenuChange })
}

/**
 * @param {React.KeyboardEvent<Element>} e
 * @param {Props & { context?: import("@floating-ui/react").FloatingRootContext<import("@floating-ui/react").ReferenceType> }} _
 */
export function internalMenuitemKeydown(e, { id, context, structure, onSubmenuChange }) {
  return handleMenuitemKeydown(e, { id, element: context, structure, onSubmenuChange })
}

/**
 * @param {React.KeyboardEvent<Element>} e
 * @param {Props & { element?: import("@floating-ui/react").FloatingRootContext<import("@floating-ui/react").ReferenceType> | HTMLElement }} _
 */
function handleMenuitemKeydown(e, { id, element, structure, onSubmenuChange }) {
  const menuItems = Object.values(structure.menu ?? {})

  if (!isHtmlElement(e.currentTarget)) return
  const currentIndex = menuItems.indexOf(e.currentTarget)

  const reference = element && 'elements' in element
    ? element.elements.reference
    : element

  if (id && ['ArrowDown'].includes(e.code)) {
    e.preventDefault()
    onSubmenuChange({ id, active: true })
    waitForRef(() => {
      const [item] = Object.values(structure.submenu[id])
      item.focus()
    })
  }

  if (id && ['ArrowUp'].includes(e.code)) {
    e.preventDefault()
    onSubmenuChange({ id, active: true })
    waitForRef(() => {
      const items = Object.values(structure.submenu[id])
      items[items.length - 1].focus()
    })
  }

  if (!id || e.target === reference) {
    if (['ArrowLeft'].includes(e.code)) {
      e.preventDefault()
      menuItems[currentIndex - 1]?.focus?.()
    }

    if (['ArrowRight'].includes(e.code)) {
      e.preventDefault()
      menuItems[currentIndex + 1]?.focus?.()
    }

    if (['Home'].includes(e.code)) {
      e.preventDefault()
      menuItems[0]?.focus?.()
    }

    if (['End'].includes(e.code)) {
      e.preventDefault()
      menuItems[menuItems.length - 1]?.focus?.()
    }
  }
}

/**
 * @param {React.KeyboardEvent<Element>} e
 * @param {Props} _
 */
export function handleItemKeydown(e, { id, structure, onSubmenuChange }) {
  const submenuItems = Object.values(structure.submenu[id] ?? {})
  const menuValues = Object.values(structure.menu ?? {})
  const parentKeys = Object.keys(structure.menu ?? {})

  const parentIndex = parentKeys.indexOf(id)
  const currentIndex = submenuItems.indexOf(e.currentTarget)

  if (['ArrowUp'].includes(e.code)) {
    e.preventDefault()
    submenuItems[currentIndex - 1]?.focus?.()
  }

  if (['ArrowDown'].includes(e.code)) {
    e.preventDefault()
    submenuItems[currentIndex + 1]?.focus?.()
  }

  if (['Home'].includes(e.code)) {
    e.preventDefault()
    submenuItems[0]?.focus?.()
  }

  if (['End'].includes(e.code)) {
    e.preventDefault()
    submenuItems[submenuItems.length - 1]?.focus?.()
  }

  if (['ArrowLeft'].includes(e.code)) {
    e.preventDefault()
    const id = parentKeys[parentIndex - 1]
    onSubmenuChange({ id: id || null, active: false })
    menuValues[parentIndex - 1].focus?.()
  }

  if (['ArrowRight'].includes(e.code)) {
    e.preventDefault()
    const id = parentKeys[parentIndex + 1]
    onSubmenuChange({ id: id || null, active: false })
    menuValues[parentIndex + 1].focus?.()
  }
}

/**
 * @param {EventTarget & Element} x
 * @returns {x is HTMLElement}
 */
function isHtmlElement(x) {
  return x instanceof HTMLElement
}

function waitForRef(callback) {
  setTimeout(callback, 1)
}

/**
 * @typedef {Object} Props
 * @property {String} [id]
 * @property {Structure} structure
 * @property {Function} [onSubmenuChange]
 */

/**
 * @typedef {Object} Structure
 * @property {Menu} menu
 * @property {Submenu} submenu
 */

/**
 * @typedef {Object} Menu
 * @type {Record<string, HTMLElement>} [menu]
 */

/**
 * @typedef {Object} Submenu
 * @type {Record<string, HTMLElement>} [submenu]
 */
